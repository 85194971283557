import React, { useState, useEffect } from "react";
import "./SellSection.css";
import { useNavigate } from "react-router-dom";

function SellSection() {
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [result, setResult] = useState(0);
  const [currency, setCurrency] = useState("USDT/INR");
  const [priceData, setPriceData] = useState([]);
  const [balance, setBalance] = useState({ before: 0, after: 0 });
  const [saleMessage, setSaleMessage] = useState(null);
  const token = localStorage.getItem("authToken"); // Assuming token is stored in localStorage
  const navigate = useNavigate(); // Initialize navigate hook

  // Fetch price data
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch("https://api.qbit99.com/api/getprice");
        const data = await response.json();
        if (data.success) {
          setPriceData(data.result);
        }
      } catch (error) {
        console.error("Error fetching price data", error);
      }
    };
    fetchPrices();
  }, []);

  // Update price when currency changes
  useEffect(() => {
    const selectedCurrency = priceData.find((item) => item.coin === currency);
    if (selectedCurrency) {
      setPrice(selectedCurrency.price);
    }
  }, [currency, priceData]);

  // Fetch balance data when currency changes
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await fetch("https://api.qbit99.com/api/getbalance", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        const data = await response.json();
        if (data.success) {
          const [before, after] = currency.split("/");

          const beforeBalance = data.result.find(
            (item) => item.wallet_type === before
          );
          const afterBalance = data.result.find(
            (item) => item.wallet_type === after
          );

          setBalance({
            before: beforeBalance ? beforeBalance.balance : 0,
            after: afterBalance ? afterBalance.balance : 0,
          });
        }else {
          // Handle specific error response
          if (data.status === false && data.message === "Places login") {
            navigate("/login"); // Redirect to login
          } else {
            throw new Error(data.message || "Unknown error occurred");
          }
        }
      } catch (error) {
        console.error("Error fetching balance", error);
        setBalance({ before: 0, after: 0 });
      }
    };
    fetchBalance();
  }, [currency]);

  // Handle amount change
  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    setAmount(enteredAmount);
    setResult(enteredAmount * price);
  };

  // Handle currency change
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  // Handle sell action
  const handleSell = async (e) => {
    e.preventDefault();
    const payload = {
      amount: amount,
      price: price,
      pair: currency,
    };
    try {
      const response = await fetch("https://api.qbit99.com/api/sell", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.message) {
        setSaleMessage(data.message);
        setAmount(0);
        setResult(0);
      } else {
        setSaleMessage("Sale failed: " + data.message);
      }
    } catch (error) {
      console.error("Error making sell request", error);
      setSaleMessage("Sale failed. Please try again.");
    }
  };

  return (
    <>          <span className="buy-card">Sell</span>

    <form className="sell-form" onSubmit={handleSell}>
      {/* Balance Overview */}
      <div className="balance-overview-container">
        <div className="balance-item">
        </div>
        <div className="balance-item">
          <span className="label">{currency.split("/")[0]} :</span>
          <span className="value">{balance.before.toFixed(2)}</span>
        </div>
        <div className="balance-item">
          <span className="label">{currency.split("/")[1]} :</span>
          <span className="value">{balance.after.toFixed(2)}</span>
        </div>
      </div>

      {/* Form Fields */}
      <div className="form-field">
        <div className="sell-input-container">
          <select
            className="currency-select"
            value={currency}
            onChange={handleCurrencyChange}
            required
          >
            <option value="USDT/INR">USDT/INR</option>
            <option value="TRX/INR">TRX/INR</option>
          </select>
          <input
            type="number"
            className="currency-input"
            placeholder="Enter Amount"
            value={amount}
            onChange={handleAmountChange}
            required
          />
        </div>
      </div>

      <div className="price-field">
        <span className="price-display">RATE {price}</span>
        <input
          type="number"
          className="currency-output"
          placeholder="BTC"
          value={result}
          readOnly
        />
      </div>

      <button type="submit" className="btn-action">
        Confirm
      </button>

      {saleMessage && (
        <div className="sale-message">
          <p>{saleMessage}</p>
        </div>
      )}
    </form>
    </>
  );
}

export default SellSection;
