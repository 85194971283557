import React, { useEffect, useState } from "react";
import "./DepositPage.css"; // Styling
import { useLocation,useNavigate } from "react-router-dom";

const token = localStorage.getItem("authToken"); // Assuming token is stored in localStorage

function DepositPage() {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(""); // State to track errors
  const location = useLocation();
  const wallet_address = location.state?.wallet_address;
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    // Fetch the deposit address and history
    fetch(`https://api.qbit99.com/api/getDepositData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setHistory(data || []); // Set deposit history
        } else {
          navigate('/login');

          setError("Failed to fetch deposit data");
        }
      })
      .catch((error) => {
        navigate('/login');
        console.error("Error fetching deposit data:", error);
        setError("Error fetching deposit data. Please try again later.");
      });
  }, []); // Empty dependency array to run only once

  // Function to copy the deposit address to clipboard
  const copyAddressToClipboard = () => {
    if (!wallet_address) {
      alert("No address to copy!");
      return;
    }
    navigator.clipboard
      .writeText(wallet_address)
      .then(() => {
        alert("Address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy address:", error);
        alert("Failed to copy address.");
      });
  };

  return (
    <div className="deposit-page-wrapper">
      <div className="deposit-page">
        {/* Error Message */}
        {error && <p className="error">{error}</p>}

        {/* Deposit Address */}
        <div className="deposit-address">
          <h2>Deposit Address</h2>
          <h2>
            {wallet_address ? (
              wallet_address
            ) : (
              <span style={{ color: "gray" }}>Fetching address...</span>
            )}
            <button
              onClick={copyAddressToClipboard}
              style={{ marginLeft: "10px" }}
              disabled={!wallet_address}
            >
              Copy
            </button>
          </h2>
        </div>

        {/* Deposit History Table */}
        <div className="deposit-history">
          <h2>Deposit History</h2>
          {history.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Txr ID</th>
                  <th>Symbol</th>
                  <th>Amount</th>
                  <th>Fee</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {history.map((entry, index) => (
                  <tr key={index}>
                    <td title={entry.tx_id}>
                      {entry.tx_id.substring(0, 9)}...
                    </td>
                    <td>{entry.symbol}</td>
                    <td>{entry.amount}</td>
                    <td>{entry.fee}</td>
                    <td>{entry.status === 0 ? "Completed" : "Pending"}</td>
                    <td>{new Date(entry.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : error ? (
            <p className="error">{error}</p>
          ) : (
            <p style={{ color: "gray" }}>No deposit history available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default DepositPage;
