import React, { useEffect, useState } from "react";
import "./DepositPage.css"; // Optional for styling

const token = localStorage.getItem("authToken"); // Assuming token is stored in localStorage

function DepositPage() {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(""); // State to track errors
  const [amount, setAmount] = useState(""); // State to store amount
  const [utrNumber, setUtrNumber] = useState(""); // State for UTR number
  const [image, setImage] = useState(null); // State for storing the uploaded image
  const [uploading, setUploading] = useState(false); // To track if the file is being uploaded

  useEffect(() => {
    // Fetch the deposit address and history
    fetch(`https://api.qbit99.com/api/getInrDepositData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setHistory(data || []); // Set deposit history
        } else {
          setError("Failed to fetch deposit data");
        }
      })
      .catch((error) => {
        console.error("Error fetching deposit data:", error);
        setError("Error fetching deposit data. Please try again later.");
      });
  }, []); // Empty dependency array to run only once

  const handleFileChange = (event) => {
    setImage(event.target.files[0]); // Set the selected image file
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!amount || !utrNumber || !image) {
      setError("Please fill out all fields and select an image.");
      return;
    }

    setUploading(true); // Set uploading state to true

    const formData = new FormData();
    formData.append("file", image);
    formData.append("amount", amount);
    formData.append("utr_number", utrNumber);

    try {
      const response = await fetch("https://api.qbit99.com/api/upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result) {
        setError(""); // Clear any previous error
        alert('Deposit request submitted successfully');
        setAmount("");
        setUtrNumber("");
        setImage(null);
      } else {
        setError("Failed to upload data. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      setError("Error uploading data. Please try again.");
    } finally {
      setUploading(false); // Set uploading state to false after API call
    }
  };

  return (
    <div className="deposit-page-wrapper">
      <div className="deposit-page">
        {/* QR Code Section */}
        <div className="qr-code-section">
          <h3>Scan QR Code for Payment</h3>
          <img src={`https://api.qbit99.com/api/pay/qr.png`} alt="QR Code" className="qr-code-image" />
        </div>

        {/* Deposit Form */}
        <div className="deposit-form">
          <h3>Upload Deposit Details</h3>
          <form onSubmit={handleUpload}>
            <div>
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="utrNumber">UTR Number:</label>
              <input
                type="text"
                id="utrNumber"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="file">Upload Image:</label>
              <input
                type="file"
                id="file"
                onChange={handleFileChange}
                accept="image/*"
                required
              />
            </div>
            <button type="submit" disabled={uploading}>
              {uploading ? "Uploading..." : "Upload Deposit"}
            </button>
          </form>
        </div>

        {/* Error Message */}
        {error && <p className="error">{error}</p>}

        <div className="deposit-history">
          <h2>INR Deposit History</h2>
          {history.length > 0 ? (
            <table>
              <thead style={{ backgroundColor: "black" }}>
                <tr>
                  <th>Txr ID</th>
                  <th>Symbol</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {history.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.order_id.substring(0, 9)}</td>
                    <td>{entry.symbol}</td>
                    <td>{entry.amount}</td>
                    <td>{entry.status ? "Completed" : "Pending"}</td>
                    <td>{new Date(entry.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No deposit history available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default DepositPage;
