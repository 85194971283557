import React, { useEffect, useState } from "react";
import "./DepositPage.css"; // Optional for styling

const token = localStorage.getItem("authToken"); // Assuming token is stored in localStorage

function DepositPage() {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(""); // State to track errors
  const [withdrawalData, setWithdrawalData] = useState({
    amount: "",
    address: "",
    symbol: "USDT",
  });
  const [withdrawalMessage, setWithdrawalMessage] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false); // State to toggle OTP popup
  const [otp, setOtp] = useState(""); // State for OTP input

  useEffect(() => {
    // Fetch the deposit address and history
    fetch(`https://api.qbit99.com/api/getwithdrawalData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setHistory(data || []); // Set withdrawal history
        } else {
          setError("Failed to fetch withdrawal data");
        }
      })
      .catch((error) => {
        console.error("Error fetching withdrawal data:", error);
        setError("Error fetching withdrawal data. Please try again later.");
      });
  }, []); // Empty dependency array to run only once

  // Handle withdrawal input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalData({ ...withdrawalData, [name]: value });
  };

  // Handle withdrawal submission
  const handleWithdrawalSubmit = (e) => {
    e.preventDefault();

    fetch(`https://api.qbit99.com/api/coinwithdrawal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(withdrawalData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setShowOtpPopup(true); // Show OTP popup
          setWithdrawalMessage(
            "Withdrawal request submitted successfully. Please verify OTP."
          );
        } else {
          setWithdrawalMessage("Failed to process withdrawal request.");
        }
      })
      .catch((error) => {
        console.error("Error submitting withdrawal request:", error);
        setWithdrawalMessage("An error occurred. Please try again.");
      });
  };

  // Handle OTP verification
  const handleOtpVerification = () => {
    fetch(`https://api.qbit99.com/api/withdrawal-verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ otp }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setWithdrawalMessage("OTP verified successfully.");

          setShowOtpPopup(false); // Close the popup on success
        } else {
          setWithdrawalMessage("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        setWithdrawalMessage("An error occurred. Please try again.");
      });
  };

  return (
    <div className="deposit-page-wrapper">
      <div className="deposit-page">
        {/* Error Message */}
        {error && <p className="error">{error}</p>}

        {/* Withdrawal Box */}
        <div className="withdrawal-box">
          <h2>Withdrawal</h2>
          <form onSubmit={handleWithdrawalSubmit}>
            <div className="form-group">
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={withdrawalData.amount}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="symbol">Symbol:</label>
              <select
                id="symbol"
                name="symbol"
                value={withdrawalData.symbol}
                onChange={handleInputChange}
                required
              >
                <option value="USDT">USDT</option>
                <option value="TRX">TRX</option>
                <option value="INR">INR</option>
              </select>
            </div>
            {withdrawalData.symbol !== "INR" && (
              <div className="form-group">
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={withdrawalData.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            )}
            <button type="submit" className="withdraw-button">
              Submit Withdrawal
            </button>
          </form>
          {withdrawalMessage && <p>{withdrawalMessage}</p>}
        </div>

        {/* Withdrawal History Table */}
        <div className="deposit-history">
          <h2>Withdrawal History</h2>
          {history.length > 0 ? (
            <table>
              <thead style={{ backgroundColor: "black" }}>
                <tr>
                  <th>Txr ID</th>
                  <th>Symbol</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {history.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.tx_id.substring(0, 9)}</td>{" "}
                    {/* Show only first 9 characters of tx_id */}
                    <td>{entry.symbol}</td>
                    <td>{entry.amount}</td>
                    <td>{entry.status ? "Completed" : "Pending"}</td>
                    <td>{new Date(entry.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No withdrawal history available.</p>
          )}
        </div>

        {/* OTP Verification Popup */}
        {/* OTP Verification Message */}

        {/* OTP Verification Popup */}
        {showOtpPopup && (
          <div className="otp-popup">
            <div className="popup-content">
              <h3>Verify OTP</h3>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <button onClick={handleOtpVerification}>Verify</button>
              <button onClick={() => setShowOtpPopup(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DepositPage;
