import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './components/Header';
// Import other components...
import Dashboard from "./components/Dashboard";
import Buy from "./components/Buy";
import Sell from "./components/Sell";
import Order from "./components/Order";
import LoginPage from "./components/LoginPage";
import DepositPage from "./components/DepositPage";
import Withdrawal from "./components/withdrawal";
import Inrdeposit from "./components/Inrdeposit";
import BankDetails from "./components/BankDetails";
import { AuthProvider } from "./AuthContext";

function App() {

  return (
    <Router>
            <AuthProvider>

      <Header />
      <Routes>
      <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/order" element={<Order />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/Deposit" element={<DepositPage />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/inrdeposit" element={<Inrdeposit />} />
        <Route path="/BankDetails" element={<BankDetails />} />

        {/* Define other routes */}
      </Routes>
      </AuthProvider>

    </Router>
  );
}

export default App;
