import React, { useEffect, useState } from "react";
import axios from "axios";
import "./BankDetails.css";
import { useNavigate } from "react-router-dom";

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState({
    bank_name: "",
    name: "",
    account_number: "",
    ifsc: "",
    upiid: "",
    account_type: "",
  });
  const [userDetails, setUserDetails] = useState(null);
  const [isAddingBankDetails, setIsAddingBankDetails] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // For Reset Password modal
  const [newPassword, setNewPassword] = useState(""); // For new password input
  const [otp, setOtp] = useState(""); // For OTP input
  const navigate = useNavigate(); // Initialize navigate hook
  const [msg, setMsg] = useState(null);

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.post(
          "https://api.qbit99.com/api/getprofile",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status) {
          const { user, bank_details } = response.data.result;
          setUserDetails(user);
          if (bank_details) {
            setBankDetails(bank_details);
          }
        } else {
          navigate("/login");
          setError("Failed to fetch user data.");
        }
      } catch (err) {
        navigate("/login");

        setError("An error occurred while fetching profile data.");
      }
    };

    fetchProfile();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddBankDetailsClick = () => {
    setIsAddingBankDetails(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !bankDetails.bank_name ||
      !bankDetails.name ||
      !bankDetails.account_number ||
      !bankDetails.ifsc ||
      !bankDetails.upiid ||
      !bankDetails.account_type
    ) {
      setError("Please fill in all fields.");
      return;
    }

    const confirmSave = window.confirm(
      "Are you sure you want to save these bank details? They can only be added once."
    );
    if (!confirmSave) return;

    try {
      setIsSubmitting(true);
      const response = await axios.post(
        "https://api.qbit99.com/api/save-bank-details",
        bankDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // alert(response.data.message);
      setError(response.data.message);
      setIsAddingBankDetails(false);
    } catch (err) {
      setError("Failed to save bank details.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPasswordClick = async () => {
    try {
      const response = await axios.post(
        "https://api.qbit99.com/api/sendotp",
        { token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        // alert("OTP sent successfully!");
        setError(response.data.message);

        setIsModalOpen(true); // Open the modal
      } else {
        alert("Failed to send OTP.");
      }
    } catch (err) {
      setError("Failed to send OTP.");
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.qbit99.com/api/reset-password",
        { otp, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        alert("Password reset successfully!");
        setIsModalOpen(false);
        setOtp("");
        setNewPassword("");
      } else {
        alert("Failed to reset password.");
      }
    } catch (err) {
      setError("Failed to reset password.");
    }
  };

  return (
    <div className="container">
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="content">
        {/* User Details Section */}
        <div className="user-details-container">
          <div className="card">
            <h2>User Details</h2>
            {userDetails ? (
              <div className="user-details">
                <p>
                  <strong>Name:</strong> {userDetails.name}
                </p>
                <p>
                  <strong>Email:</strong> {userDetails.email}
                </p>
                <p>
                  <strong>Mobile:</strong> {userDetails.mobileNo}
                </p>
              </div>
            ) : (
              <p>Loading user details...</p>
            )}
            <button onClick={handleResetPasswordClick}>Reset Password</button>
          </div>
        </div>

        {/* Bank Details Section */}
        <div className="bank-details-container">
          {bankDetails.bank_name && !isAddingBankDetails ? (
            <div className="card">
              <h2>Bank Details</h2>
              <p>
                <strong>Bank Name:</strong> {bankDetails.bank_name}
              </p>
              <p>
                <strong>Account Holder:</strong> {bankDetails.name}
              </p>
              <p>
                <strong>Account Number:</strong> {bankDetails.account_number}
              </p>
              <p>
                <strong>IFSC:</strong> {bankDetails.ifsc}
              </p>
              <p>
                <strong>UPI ID:</strong> {bankDetails.upiid}
              </p>
              <p>
                <strong>Account Type:</strong> {bankDetails.account_type}
              </p>
            </div>
          ) : (
            <div className="card">
              <h2>Add Bank Details</h2>
              {isAddingBankDetails ? (
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={bankDetails.bank_name}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="name"
                    placeholder="Account Holder Name"
                    value={bankDetails.name}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="account_number"
                    placeholder="Account Number"
                    value={bankDetails.account_number}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="ifsc"
                    placeholder="IFSC Code"
                    value={bankDetails.ifsc}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="upiid"
                    placeholder="UPI ID"
                    value={bankDetails.upiid}
                    onChange={handleChange}
                  />
                  <select
                    name="account_type"
                    value={bankDetails.account_type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Account Type
                    </option>
                    <option value="Savings">Savings</option>
                    <option value="Current">Current</option>
                  </select>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save Bank Details"}
                  </button>
                </form>
              ) : (
                <button onClick={handleAddBankDetailsClick}>
                  Add Bank Details
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Reset Password Modal */}
      {isModalOpen && (
        <div className="modal">
          <form onSubmit={handleResetPasswordSubmit} className="form">
            <h2>Reset Password</h2>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <input
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button type="submit">Reset Password</button>
            <button type="button" onClick={() => setIsModalOpen(false)}>
              Cancel
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default BankDetails;
