import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import logo from "../image/logo.jpg"; // Ensure the path to the logo is correct

function Header() {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [token, setToken] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setToken(null);
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    setToken(storedToken);
  }, []);

  return (
    <header className="header">
      {/* Hamburger button for mobile view */}
      <div className="hamburger" onClick={toggleSidebar}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Sidebar Navigation */}
      <nav className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
      <div className="logo">
        <img src={logo} className="logo" alt="Logo" />
      </div>
        <ul className="nav-links">
          <li onClick={() => handleNavigation("/dashboard")}>Dashboard</li>
          <li onClick={() => handleNavigation("/buy")}>Buy</li>
          <li onClick={() => handleNavigation("/sell")}>Sell</li>
          <li onClick={() => handleNavigation("/order")}>Order</li>
          <li onClick={() => handleNavigation("/BankDetails")}>Settings</li>
          {token ? (
            <li onClick={handleLogout}>Logout</li>
          ) : (
            <li onClick={() => handleNavigation("/login")}>Login</li>
          )}
        </ul>
      </nav>

      {/* Logo on the right */}
      <div className="logo1">
        <img src={logo} className="logo1" alt="Logo" />
      </div>
    </header>
  );
}

export default Header;

