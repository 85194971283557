import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [balances, setBalances] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [token] = useState(localStorage.getItem("authToken")); // Retrieve token
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    // Fetch the balance data
    fetch("https://api.qbit99.com/api/getbalance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
      body: JSON.stringify({ userid: "sahu" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Set the balance data to state
          setBalances(data.result);

          // Calculate the total value (sum of all balances)
         
          const total = data.totalSumInINR
          setTotalValue(total);
        } else {
          // If token is expired or invalid, redirect to LoginPage
          localStorage.removeItem("authToken");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error fetching balance data:", error);
        navigate("/login");
      });
  }, [token, navigate]);

  // Function to handle deposit action
  const handleDeposit = (walletType,wallet_address) => {
    if (walletType === "INR") {
      navigate("/inrdeposit"); // Navigate to INR deposit page
    } else {
      navigate("/Deposit", {
        state: { wallet_address }, // Pass wallet_address via state
      });    }
  };

  // Function to handle withdraw action
  const handleWithdraw = (walletType) => {
    navigate("/withdrawal");
  };

  return (
    <div className="dashboard">
 
        <div className="total-value">
          <h4 className="buy-card">Total Value: {totalValue}</h4>
          
        </div>

        {balances.length > 0 ? (
          <div className="metrics">
            {balances.map((balance) => (
              <div key={balance._id} className="card">
                <h3>
                  {balance.wallet_type} Balance: {balance.balance}
                </h3>

                <div className="buttons">
                  <button
                    className="deposit-btn"
                    onClick={() => handleDeposit(balance.wallet_type,balance.wallet_address)} // Pass wallet type
                  >
                    Deposit
                  </button>
                  <button
                    className="withdrawal-btn"
                    onClick={() => handleWithdraw(balance.wallet_type)} // Pass wallet type
                  >
                    Withdraw
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading balances...</p>
        )}
    </div>
  );
}

export default Dashboard;
