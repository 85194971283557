import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LoginPage.css";
import Dashboard from "./Dashboard";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showRegistration, setShowRegistration] = useState(false); // State to toggle between login and registration
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorMessage("Please fill in both fields.");
      return;
    }
    try {
      const response = await axios.post("https://api.qbit99.com/api/login", {
        email,
        password,
      });
      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.token);
        // alert("Login successful");
        navigate("/Dashboard"); // Adjust the path as needed for your login page
        window.location.reload(); // Reload the page after navigation
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response?.data || "Login failed.");
    }
  };

  return (
    <div className="login-container">
      <h2>{showRegistration ? "Registration" : "Login"}</h2>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {/* Toggle between Login and Registration Form */}
      {!showRegistration ? (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>

          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      ) : (
        <Registration setShowRegistration={setShowRegistration} />
      )}

      <div className="toggle-link">
        <button onClick={() => setShowRegistration(!showRegistration)}>
          {showRegistration
            ? "Already have an account? Login"
            : "Don’t have an account? Register"}
        </button>
      </div>
    </div>
  );
}

function Registration({ setShowRegistration }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setname] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);

  const navigate = useNavigate(); // Use React Router's navigate hook

  const handleRegistration = async (e) => {
    e.preventDefault();

    if (!email || !password || !confirmPassword) {
      setErrorMessage("All fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post("https://api.qbit99.com/api/register", {
        email,
        password,
        mobileNo,
        name
        
      });
      if (response.status === 201) {
        // alert("Registration successful! Please verify your email.");
        setShowOtpPopup(true);
        setErrorMessage("Registration successful! Please verify your email.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response?.data || "Registration failed.");
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();

    if (!otp) {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.qbit99.com/api/verify-otp",
        { email, otp,mobileNo,name }
      );
      if (response.status === 200) {
        alert("Email verified successfully!");
        setShowRegistration(false); // Go back to login page after successful OTP verification
        navigate("/login"); // Navigate to the login page
        setErrorMessage("");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response?.data || "OTP verification failed.");
    }
  };

  return (
    <div className="registration-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleRegistration} className="registration-form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="name"
            id="name"
            value={name}
            onChange={(e) => setname(e.target.value)}
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobileNo">mobile Number</label>
          <input
            type="mobileNo"
            id="mobileNo"
            value={mobileNo}
            onChange={(e) => setmobileNo(e.target.value)}
            placeholder="Enter your mobileNo"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your password"
            required
          />
        </div>
        <button type="submit" className="register-btn">
          Register
        </button>
      </form>

      {showOtpPopup && (
        <div className="otp-popup">
          <h3>Verify Email</h3>
          <form onSubmit={handleOtpVerification}>
            <div className="form-group">
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                required
              />
            </div>
            <button type="submit" className="verify-btn">
              Verify
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
